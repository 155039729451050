<template></template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  created() {
    if (this.id == "dutic") {
      window.location.href = "https://classroom.google.com/u/0/h";
    }
    if (this.id == "soporte") {
      window.location.href =
        "https://www.unsa.edu.pe/ucps/tutorial-estudiantil/";
    }
  },
};
</script>